// @flow

import React from "react";
import {
    DefaultLayout,
    AdvancedSearchAssignments,
    Layout,
    withPrivateRoute,
} from "@containers";
import { Helmet } from "react-helmet";

/**
 * Home Page
 */
const AdvancedSearchAssignmentsPage = (props: *) => (
    <Layout {...props}>
        <Helmet>
            <title>Geavanceerd zoeken: Opdrachten</title>
        </Helmet>
        <DefaultLayout title="Geavanceerd zoeken: Opdrachten">
            <AdvancedSearchAssignments />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AdvancedSearchAssignmentsPage);
